import React from 'react';
import style from './style.css';
import IconSchool from './images/IconSchool.png';
import { NavLink } from 'react-router-dom';

const Boilerplate = window.cccisd.boilerplate;

export default {
    icon: IconSchool,
    heading: 'Welcome to SHAPE! Explore all that SHAPE has to offer to improve your school mental health system.',
    data: [
        {
            title: 'Map your school mental health system and supports',
            icon: <div className={style.MapSchoolMentalHealthServices} />,
            link: Boilerplate.url('/mhProfile'),
            text: (
                <>
                    Teams are encouraged to start with the{' '}
                    <NavLink to="/mhProfile" className={style.highlight}>
                        School Mental Health Profile
                    </NavLink>
                    , which asks about the structure and operations of your school mental health system. This profile is
                    part of the National School Mental Health Census, an effort to capture the status of school mental
                    health nationally.
                </>
            ),
        },
        {
            title: 'Assess system quality using national performance measures ',
            icon: <div className={style.AssessSystemQuality} />,
            text: (
                <div className="flex-overview">
                    <NavLink to="/mentalHealthQuality">
                        <div className="tooltips">
                            School Mental Health Quality Assessment
                            <span className="tooltip-text">
                                <p>
                                    The Quality Assessment will help your team assess the quality of your school mental
                                    health system based on seven domains.
                                </p>
                                <p>Teams receive a custom report based on responses.</p>
                                <div className="box-45-deg" />
                            </span>
                        </div>
                    </NavLink>

                    <NavLink to="/traumaResponsiveness">
                        <div className="tooltips">
                            Trauma Responsiveness
                            <span className="tooltip-text">
                                <p>
                                    The Trauma Responsive Schools Implementation Assessment (TRS-IA) is an
                                    evidence-informed self-assessment.
                                </p>
                                <p>
                                    Feedback reports and comprehensive guides will be generated to support schools and
                                    districts as they work to enhance their trauma-responsive programming.
                                </p>
                                <div className="box-45-deg" />
                            </span>
                        </div>
                    </NavLink>

                    <NavLink to="/owbi">
                        <div className="tooltips">
                            Organizational Well-Being Inventory for Schools (OWBI-S)
                            <span className="tooltip-text">
                                <p>
                                    The Organizational Well-Being Inventory (OWBI) assesses the quality of their
                                    school’s performance in promoting and maintaining the physical, psychological, and
                                    social health for all employees based on 8 domains.
                                </p>
                                <p>
                                    Teams will receive customized reports that offer schools an analysis of their
                                    strengths and areas for improvement.
                                </p>
                                <div className="box-45-deg" />
                            </span>
                        </div>
                    </NavLink>

                    <NavLink to="/care">
                        <div className="tooltips">
                            Culturally Responsive, Anti-Racist, and Equitable (CARE)
                            <span className="tooltip-text">
                                <p>
                                    The Culturally Responsive, Anti-Racist, and Equitable (CARE) School Assessment is a
                                    40-item quality improvement tool covering five domains to promote an environment
                                    that reflects values around diversity, equity, and inclusion. Teams receive a custom
                                    report with a summary of their responses.
                                </p>
                                <div className="box-45-deg" />
                            </span>
                        </div>
                    </NavLink>
                </div>
            ),
        },
        {
            title: 'Improve your school mental health system',
            icon: <div className={style.ReceiveCustomReports} />,
            text: (
                <>
                    <li>Custom reports help guide your team’s strategic planning for system improvement.</li>
                    <li>Use the Resource Library to inform continuous quality improvement.</li>
                </>
            ),
        },
        {
            title: 'Learn & Share SHAPE',
            icon: <div className={style.UtilizeAdditionalSHAPEFeatures} />,
            link: Boilerplate.url('/learnAndShareShape'),
            text: (
                <>
                    <li>Learn about SHAPE features and how to share information about the SHAPE system with others.</li>
                </>
            ),
        },
    ],
};
